import React from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { Subscription } from "rxjs";
import { useAppDispatch } from "../../hooks";
import { startTest } from "../../reducers/Testing";
import { Signal } from "../../Signal";
import { ReceivedRtcpStats } from "../../Stats";
import { TestEngine } from "../../TestEngine";

interface Props {}

const Home: React.FunctionComponent<Props> = (props: Props) => {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  return (
    <div>
      <div className="h-screen flex justify-center items-center">
        <div
          className="hover:bg-gray-50 active:bg-gray-200 active:shadow-sm select-none p-6 max-w-sm bg-white rounded-xl shadow-md flex items-center space-x-4 justify-center cursor-pointer text-xl font-medium text-black"
          onClick={() => {
            dispatch(startTest());
            navigate("/test");
          }}
        >
          Start
        </div>
      </div>
    </div>
  );
};

export default Home;
