import React, { useEffect, useRef } from "react";
import { useAppSelector } from "../../hooks";
import { AutoSizer } from "react-virtualized";

interface Props {}

let lastIndex: number = 0;
let lastX: number | undefined = undefined;
let lastY: number | undefined = undefined;

const WaveformView: React.FunctionComponent<Props> = (props: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const wholeWaveformData = useAppSelector(
    (state) => state.testing.wholeWaveformData
  );

  useEffect(() => {
    if (!wholeWaveformData) {
      return;
    }
    const canvas = canvasRef.current!!;
    const context = canvas.getContext("2d")!!;
    context.imageSmoothingEnabled = true;
    // context.fillStyle = "#000000";
    // context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    context.lineWidth = 1;
    context.strokeStyle = "#000000";

    context.beginPath();

    let sliceWidth = (context.canvas.width * 1.0) / 1100000;
    let x = (lastIndex || 0) * sliceWidth;
    context.moveTo(lastX || 0, lastY || 128);
    for (; lastIndex < wholeWaveformData.length; lastIndex++) {
      var v = wholeWaveformData[lastIndex] / 128.0;
      var y = (v * context.canvas.height) / 2;

      if (lastIndex === 0) {
        context.moveTo(x, y);
      } else {
        context.lineTo(x, y);
      }

      x += sliceWidth;
    }

    context.stroke();
  }, [wholeWaveformData]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <canvas ref={canvasRef} {...props} width={width} height={600} />
      )}
    </AutoSizer>
  );
};

const WaveformView2: React.FunctionComponent<Props> = (props: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const waveformData = useAppSelector((state) => state.testing.waveformData);
  const wholeWaveformData = useAppSelector(
    (state) => state.testing.wholeWaveformData
  );

  useEffect(() => {
    if (!waveformData || !wholeWaveformData) {
      return;
    }
    const canvas = canvasRef.current!!;
    const context = canvas.getContext("2d")!!;
    context.imageSmoothingEnabled = true;
    context.fillStyle = "#000000";
    context.fillRect(0, 0, context.canvas.width, context.canvas.height);

    context.lineWidth = 1;
    context.strokeStyle = "#ffffff";
    context.globalAlpha = 1;
    context.beginPath();

    var sliceWidth = (context.canvas.width * 1.0) / (1500000 / 500);
    var x = 0;

    for (var i = 0; i < waveformData.length; i++) {
      var v = waveformData[i] / 128.0;
      var y = (v * context.canvas.height) / 2;

      if (i === 0) {
        context.moveTo(x, y);
      } else {
        context.lineTo(x, y);
      }

      x += sliceWidth;
    }

    context.lineTo(x, canvas.height / 2);
    context.lineTo(canvas.width, canvas.height / 2);
    context.stroke();

    context.strokeStyle = "#ff0000";
    context.globalAlpha = 0.5;
    context.beginPath();

    var sliceWidth = (context.canvas.width * 1.0) / (1500000 / 500);
    var x = 0;

    for (var i = 0; i < wholeWaveformData.length; i++) {
      var v = wholeWaveformData[i] / 128.0;
      var y = (v * context.canvas.height) / 2;

      if (i === 0) {
        context.moveTo(x, y);
      } else {
        context.lineTo(x, y);
      }

      x += sliceWidth;
    }
    context.lineTo(x, canvas.height / 2);
    context.lineTo(canvas.width, canvas.height / 2);
    context.stroke();
  }, [waveformData, wholeWaveformData]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <canvas ref={canvasRef} {...props} width={width} height={600} />
      )}
    </AutoSizer>
  );
};

export default WaveformView2;
