import { Link, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/home/HomePage";
import ResultPage from "./pages/result/ResultPage";
import TestingPage from "./pages/testing/TestingPage";

function App() {
  return (
    <div className="App">
      <div className="text-sm font-medium text-gray-900 py-5 ring-1 ring-gray-900 ring-opacity-5 shadow-sm">
        <span className="px-8"><Link to="/">VOICETEST.net</Link></span>
      </div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/test" element={<TestingPage />} />
        <Route path="/result" element={<ResultPage />} />
      </Routes>
    </div>
  );
}

export default App;
