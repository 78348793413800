import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { Message } from "./Message";
import { Signal } from "./Signal";
import { ReceivedRtcpStats } from "./Stats";
import { sleep } from "./Utils";
import "webrtc-adapter";
import { Stream } from "stream";
import saveAs from "file-saver";
import RecordRTC, { invokeSaveAsDialog } from "recordrtc";

export class TestEngine {
  private signal: Signal;
  private audioFileUrl: string;
  private connection: RTCPeerConnection;
  private subscription: Subscription = new Subscription();
  private audioContext: AudioContext = new AudioContext();
  private gainNode: GainNode = this.audioContext.createGain();
  private webRtcConfig: RTCConfiguration = {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
    ],
  };
  private soundSource?: AudioBufferSourceNode;
  private stateSubject = new BehaviorSubject<TestEngineState>(
    TestEngineState.INITIALIZING
  );
  private serverStatsSubject = new Subject<ReceivedRtcpStats>();
  private waveformSubject = new Subject<number[]>();
  private wholeWaveformSubject = new Subject<number[]>();

  constructor(signal: Signal, audioFileUrl: string) {
    this.signal = signal;
    this.subscription.add(
      this.signal.dataStream.subscribe((message) => {
        this.onMessage(message);
      })
    );
    this.audioFileUrl = audioFileUrl;
    this.connection = new RTCPeerConnection(this.webRtcConfig);
    this.connection.onicecandidate = this.onicecandidate;
    this.connection.onconnectionstatechange = this.onConnectionStateChange;
    this.connection.oniceconnectionstatechange = this.onIceStateChange;
    this.connection.ontrack = this.onTrack;
    this.connection.addTransceiver("audio");
    this.gainNode.connect(this.audioContext.destination);
    this.gainNode.gain.value = 0;
  }

  start = async () => {
    await this.signal.init();
    fetch(this.audioFileUrl)
      .then((res) => res.arrayBuffer())
      .then(async (buffer) => {
        const audioBuffer = await this.audioContext.decodeAudioData(buffer)!!;
        this.soundSource = this.audioContext.createBufferSource()!!;
        this.soundSource.buffer = audioBuffer;
        this.soundSource.connect(this.gainNode!!);
        var destination = this.audioContext?.createMediaStreamDestination()!!;
        this.soundSource.connect(destination);
        this.createOffer(destination.stream);
      });
  };

  dispose = async () => {
    this.subscription.unsubscribe();
    this.signal.dispose();
    this.connection.close();
  };

  createOffer = async (mediaStream: MediaStream) => {
    const sendChannel = this.connection.createDataChannel("sendChannel");
    sendChannel.onmessage = (message) => {
      console.log(message.data);
    };

    const rtcRtpSender: RTCRtpSender[] = [];
    for (const track of mediaStream.getAudioTracks()) {
      console.log(track);
      const sender = this.connection.addTrack(track, mediaStream);
      rtcRtpSender.push(sender);
      track.onended = () => {
        console.log("onended");
      };
    }

    const offer = await this.connection.createOffer();
    await this.connection.setLocalDescription(offer);
    console.log(this.connection.localDescription?.toJSON());
    this.sendMessage("offer", this.connection.localDescription?.toJSON());
  };

  onicecandidate = (event: RTCPeerConnectionIceEvent) => {
    console.log("onicecandidate");
    if (event.candidate && event.candidate.candidate) {
      this.sendMessage("candidate", event.candidate.toJSON());
    }
  };

  onConnectionStateChange = async (event: Event) => {
    console.log(`onConnectionStateChange ${this.connection.connectionState}`);
    if (this.connection.connectionState === "connected") {
      if (this.state === TestEngineState.INITIALIZING) {
        this.stateSubject.next(TestEngineState.TESTING);
        this.soundSource?.start(0);
        this.connection.getSenders().forEach((sender) => {
          const kindOfTrack = sender.track?.kind;
          if (sender.transport) {
            const transport = sender.transport as any;
            if (transport.iceTransport) {
              console.log(transport.iceTransport.getSelectedCandidatePair());
            }
          }
        });
      }
    }
    // if (this.state === TestEngineState.TESTING) {
    //   if (this.connection.connectionState === "disconnected") {
    //     this.stateSubject.next(TestEngineState.TESTING);
    //   }
    // }

    // switch (this.connection.connectionState) {
    //   case "closed":
    //   case "disconnected":
    //   case "failed":
    //   case "new":
    //     {
    //       this.stateSubject.next(TestEngineState.DISCONNECTED);
    //     }
    //     break;
    //   case "connecting":
    //     {
    //       this.stateSubject.next(TestEngineState.INITIALIZING);
    //     }
    //     break;
    //   case "connected":
    //     {
    //       this.stateSubject.next(TestEngineState.CONNECTED);
    //     }
    //     break;
    // }
  };

  onIceStateChange = (event: Event) => {
    console.log(`onIceStateChange ${this.connection.iceConnectionState}`);
    if (this.connection.iceConnectionState === "connected") {
      if (this.state === TestEngineState.INITIALIZING) {
        this.stateSubject.next(TestEngineState.TESTING);
        this.soundSource?.start(0);
        this.connection.getSenders().forEach((sender) => {
          const kindOfTrack = sender.track?.kind;
          if (sender.transport) {
            const transport = sender.transport as any;
            if (transport.iceTransport) {
              console.log(transport.iceTransport.getSelectedCandidatePair());
            }
          }
        });
      }
    }
  };

  onTrack = async (event: RTCTrackEvent) => {
    console.log("onTrack");
    console.log(event.track.id);

    console.log(event.type);

    this.getStreamStats();

    // const recorder = new RecordRTC(event.streams[0], {
    //   type: "audio",
    //   mimeType: "audio/wav",
    //   recorderType: RecordRTC.StereoAudioRecorder,
    // });

    // recorder.startRecording();

    // this.subscription.add(async () => {
    //   recorder.stopRecording(function () {
    //     let blob = recorder.getBlob();
    //     invokeSaveAsDialog(blob);
    //   });
    // });

    const stream = event.streams[0];
    const audioCtx = new window.AudioContext();
    const source = audioCtx.createMediaStreamSource(stream);
    this.generateWaveform(audioCtx, source, this.wholeWaveformSubject);

    // const timerId = setInterval(() => {
    //   var dataArray = new Uint8Array(analyser.frequencyBinCount);
    //   analyser.getByteTimeDomainData(dataArray);
    //   // console.log("getByteTimeDomainData");
    //   // console.log(dataArray);
    //   this.waveformSubject.next(Array.from(dataArray));
    // }, 100);

    const audio = new Audio();
    audio.srcObject = event.streams[0];
    audio.volume = 0;
    audio.play();
  };

  generateWaveform = async (
    audioCtx: AudioContext,
    source: AudioNode,
    notifySubject: Subject<number[]>
  ) => {
    const analyser = audioCtx.createAnalyser();
    let processor = audioCtx.createScriptProcessor(1024, 1, 1);
    if (processor == null) {
      if ((audioCtx as any).createJavaScriptNode) {
        processor = (audioCtx as any).createJavaScriptNode(1024, 1, 1);
      }
    }
    let waveformData: number[] = [];
    processor.onaudioprocess = (event) => {
      const audioBuffer = event.inputBuffer;
      const rate = audioBuffer.sampleRate;
      const audioVec = audioBuffer
        .getChannelData(0)
        .map((a) => Math.floor(a * 128) + 128);
      const arr = Array.from(audioVec);
      const max = Math.max.apply(null, arr);
      const min = Math.min.apply(null, arr);
      waveformData = [...waveformData, max, min];
      notifySubject.next(waveformData);
    };

    analyser.connect(processor);
    processor.connect(audioCtx.destination);
    source.connect(analyser);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;
    var dataArray = new Uint8Array(analyser.frequencyBinCount);

    analyser.getByteTimeDomainData(dataArray);

    this.subscription.add(() => {
      processor.disconnect();
      source.disconnect();
    });
  };

  getStreamStats = async () => {
    console.log(`getStreamStats`);
    let lastPacketReceivedTimestamp: number | undefined = undefined;
    let lastPacketReceivedTimestampLocalTimestamp: number | undefined =
      undefined;
    let streamEnded = false;
    let lastResult: any = null;
    while (!streamEnded) {
      if (this.state > TestEngineState.TESTING) {
        return;
      }
      try {
        const stats = await this.connection.getStats();
        stats.forEach((commonReport, key) => {
          // if(value.type === "candidate-pair") {
          //   console.log(value);
          // }
          if (commonReport.type === "inbound-rtp") {
            const report = commonReport as RTCInboundRtpStreamStats;
            const now = report.timestamp;
            const bytes = report.bytesReceived || 0;
            const headerBytes = report.headerBytesReceived || 0;
            const packets = report.packetsReceived || 0;
            if (lastResult) {
              const deltaT = (now - lastResult.get(report.id).timestamp) / 1000;
              const bitrate =
                (8 * (bytes - lastResult.get(report.id).bytesReceived)) /
                deltaT;
              const headerrate =
                (8 *
                  (headerBytes -
                    lastResult.get(report.id).headerBytesReceived)) /
                deltaT;
              const packetRate =
                (8 * (packets - lastResult.get(report.id).packetsReceived)) /
                deltaT;
              console.log(
                `bitrate: ${bitrate}, headerrate: ${headerrate}, packetRate: ${packetRate}`
              );
            }
          }
          if (commonReport.type.indexOf("rtp") >= 0) {
            console.log(commonReport);
          }
          // if (value.type === "inbound-rtp") {
          //   if (
          //     value.lastPacketReceivedTimestamp !== lastPacketReceivedTimestamp
          //   ) {
          //     console.log(key, value);
          //     lastPacketReceivedTimestamp = value.lastPacketReceivedTimestamp;
          //     lastPacketReceivedTimestampLocalTimestamp = new Date().getTime();
          //   } else {
          //     if (
          //       lastPacketReceivedTimestamp &&
          //       lastPacketReceivedTimestampLocalTimestamp
          //     ) {
          //       const currentLocalTimeStamp = new Date().getTime();
          //       if (
          //         currentLocalTimeStamp -
          //           lastPacketReceivedTimestampLocalTimestamp >
          //         3000
          //       ) {
          //         streamEnded = true;
          //       }
          //     }
          //   }
          // }
        });
        lastResult = stats;
      } catch (e) {
        console.log(`getStreamStats exception ${e}`);
        break;
      }
      await sleep(1000);
    }
  };

  sendMessage = (command: string, payload: any) => {
    this.signal?.send(
      JSON.stringify({
        command: command,
        payload: JSON.stringify(payload),
      })
    );
  };

  onMessage = async (message: Message) => {
    if (message.command === "answer") {
      const desc = JSON.parse(message.payload);
      await this.connection?.setRemoteDescription(desc);
      console.log(desc);
    } else if (message.command === "candidate") {
      console.log("remote-candidate", message.payload);
      const candidate = JSON.parse(message.payload);
      await this.connection?.addIceCandidate(candidate);
    } else if (message.command === "received_rtcp_stats") {
      // const stats = JSON.parse(message.payload, (key, value) => {
      //   if ("Time" === key) {
      //     return new Date(value);
      //   }
      //   return value;
      // });
      const stats = JSON.parse(message.payload);
      this.serverStatsSubject.next(stats);
    } else if (message.command === "bye") {
      const stats = JSON.parse(message.payload);
      console.log(stats);
      this.stateSubject.next(TestEngineState.ENDED);
      this.dispose();
    }
  };

  public get stateStream(): Observable<TestEngineState> {
    return this.stateSubject.asObservable();
  }

  public get state(): TestEngineState {
    return this.stateSubject.value;
  }

  public get serverStatsStream(): Observable<ReceivedRtcpStats> {
    return this.serverStatsSubject.asObservable();
  }

  public get waveformStream(): Observable<number[]> {
    return this.waveformSubject.asObservable();
  }

  public get wholeWaveformStream(): Observable<number[]> {
    return this.wholeWaveformSubject.asObservable();
  }

  public get isEnded() {
    return (
      this.state === TestEngineState.ENDED ||
      this.state == TestEngineState.ERROR
    );
  }

  public get isTesting() {
    return !this.isEnded;
  }
}

export enum TestEngineState {
  INITIALIZING,
  TESTING,
  ENDED,
  ERROR,
}
// window.AudioWorkletNode
// class TestProcessor extends AudioWorkletProcessor {
//   process(
//     inputs: Float32Array[][],
//     outputs: Float32Array[][],
//     parameters: Map<string, Float32Array>
//   ) {
//     // const output = outputs[0];
//     // output.forEach((channel) => {
//     //   for (let i = 0; i < channel.length; i++) {
//     //     channel[i] = Math.random() * 2 - 1;
//     //   }
//     // });
//     console.log("TestProcessor.process");
//     return true;
//   }
// }

// registerProcessor("test-processor", () => new TestProcessor());
