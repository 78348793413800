import {
  Action,
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ReceivedRtcpStats } from "../Stats";
import { TestEngineState } from "../TestEngine";

interface Stats {}

interface TestingState {
  engineState?: TestEngineState;
  stats: ReceivedRtcpStats[];
  currentStats?: ReceivedRtcpStats;
  waveformData?: number[];
  wholeWaveformData?: number[];
}

const initialState: TestingState = {
  stats: [],
};

// const startTest = createAsyncThunk(
//   "testing/start",
//   async (userId, thunkAPI) => {
//     // const response = await userAPI.fetchById(userId);
//     return response.data;
//   }
// );

export const startTest = createAction("testing/start");

const slice = createSlice({
  name: "testing",
  initialState,
  reducers: {
    setEngineState(state, action: PayloadAction<TestEngineState>) {
      state.engineState = action.payload;
    },
    reset(state, action: Action) {
      state.engineState = undefined;
      state.currentStats = undefined;
      state.waveformData = undefined;
      state.stats = [];
    },
    addStats(state, action: PayloadAction<ReceivedRtcpStats>) {
      state.stats = [...state.stats, action.payload];
      state.currentStats = action.payload;
    },
    setWaveformData(state, action: PayloadAction<number[]>) {
      state.waveformData = action.payload;
    },
    setWholeWaveformData(state, action: PayloadAction<number[]>) {
      state.wholeWaveformData = action.payload;
    },
  },
});

export const {
  setEngineState,
  addStats,
  reset,
  setWaveformData,
  setWholeWaveformData,
} = slice.actions;
export default slice.reducer;
