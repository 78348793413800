import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Message } from "./Message";

export class Signal {
  private stateSubject = new BehaviorSubject<SignalState>(
    SignalState.DISCONNECTED
  );
  private dataSubject = new Subject<Message>();
  private connection?: WebSocket;
  private decoder = new TextDecoder("utf-8");

  init = async () => {
    if (this.stateSubject.value != SignalState.DISCONNECTED) {
      return;
    }
    this.stateSubject.next(SignalState.INITIALIZING);
    const signalAddress = process.env.REACT_APP_SIGNAL_ADDRESS || `${document.location.origin.replace('http','ws')}/signal`;
    console.log(`signalAddress ${signalAddress}`);
    this.connection = new WebSocket(signalAddress);
    this.connection.binaryType = "arraybuffer";
    this.connection.onopen = this.onOpen;
    this.connection.onclose = this.onClose;
    this.connection.onerror = this.onError;
    this.connection.onmessage = this.onMessage;
    return new Promise<void>((resolve, reject) => {
      const subscription = this.stateStream.subscribe((state) => {
        if (state != SignalState.INITIALIZING) {
          resolve();
          subscription.unsubscribe();
        }
      });
    });
  };

  send = (message: string) => {
    console.log("send", message);
    this.connection?.readyState === WebSocket.OPEN &&
      this.connection?.send(message);
  };

  dispose = () => {
    this.connection?.readyState === WebSocket.OPEN && this.connection?.close();
    this.connection = undefined;
  };

  private onOpen = () => {
    this.stateSubject.next(SignalState.CONNECTED);
  };

  private onClose = () => {
    this.stateSubject.next(SignalState.DISCONNECTED);
  };

  private onError = (event: Event) => {
    console.log("onSignalError", event);
  };

  private onMessage = (event: MessageEvent<ArrayBuffer>) => {
    this.dataSubject.next(JSON.parse(this.decoder.decode(event.data)));
  };

  public get stateStream(): Observable<SignalState> {
    return this.stateSubject.asObservable();
  }

  public get state(): SignalState {
    return this.stateSubject.value;
  }

  public get dataStream(): Observable<Message> {
    return this.dataSubject.asObservable();
  }
}

export enum SignalState {
  INITIALIZING,
  CONNECTED,
  DISCONNECTED,
}
