import { argv } from "process";
import React, { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Subscription } from "rxjs";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { startTest } from "../../reducers/Testing";
import { withNavigation } from "../../RouterHoc";
import { Signal } from "../../Signal";
import { ReceivedRtcpStats } from "../../Stats";
import { TestEngine, TestEngineState } from "../../TestEngine";

interface Props {}

const ResultPage: React.FunctionComponent<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const stats = useAppSelector((state) => state.testing.stats);
  const waveformData = useAppSelector((state) => state.testing.waveformData);
  const navigate = useNavigate();
  const jitterList = stats
    .map((s) => s.JitterInMillis)
    .map((jitter) => jitter || 0);
  const packetsLosts: number[] = stats
    .map((s) => s.PacketLossRatePercent)
    .map((percent) => percent || 0);
  return (
    <div>
      <div>Result</div>
      <div>
        Avg Jitter:
        {jitterList.length > 0
          ? jitterList.reduce((a, b) => a + b, 0) / jitterList.length
          : null}
      </div>
      <div>
        Avg Packets Lost%:
        {packetsLosts.length > 0
          ? packetsLosts.reduce((a, b) => a + b, 0) / packetsLosts.length
          : null}
      </div>
      <br />
      <button
        className="rounded bg-red-400 p-1 text-stone-50"
        onClick={() => {
          dispatch(startTest());
          navigate("/test");
        }}
      >
        Re-test
      </button>
    </div>
  );
};

export default ResultPage;
