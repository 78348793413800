import React, { useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Subscription } from "rxjs";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { startTest } from "../../reducers/Testing";
import { withNavigation } from "../../RouterHoc";
import { Signal } from "../../Signal";
import { ReceivedRtcpStats } from "../../Stats";
import { TestEngine, TestEngineState } from "../../TestEngine";
import WaveformView from "./WaveformView";

interface Props {}

const TestingPage: React.FunctionComponent<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const engineState = useAppSelector((state) => state.testing.engineState);
  const currentStats = useAppSelector((state) => state.testing.currentStats);
  const iceCandidates = currentStats?.ConnParams;
  const navigate = useNavigate();
  useEffect(() => {
    if (engineState == undefined) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (
      engineState == TestEngineState.ENDED ||
      engineState == TestEngineState.ERROR
    ) {
      navigate("/result");
    }
  }, [engineState]);
  return (
    <div>
      <div>Engine State: {engineState}</div>
      <div>Jitter: {currentStats?.JitterInMillis || 0}</div>
      <div>Packets Lost: {currentStats?.PacketLossRatePercent || 0}%</div>
      <div>
        User ICE: {iceCandidates?.SelectedICECandidateRemote || "Unknown"}
      </div>
      <div>
        Server ICE: {iceCandidates?.SelectedICECandidateLocal || "Unknown"}
      </div>
      <div style={{ width: "100%", height: "600px" }}>
        <WaveformView />
      </div>
    </div>
  );
};

export default TestingPage;
