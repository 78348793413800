import { Action, configureStore, Middleware } from "@reduxjs/toolkit";
import Testing from "./reducers/Testing";
import { testManagerMiddleware } from "./testManagerMiddleware";
// ...

const store = configureStore({
  reducer: {
    testing: Testing,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(testManagerMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
