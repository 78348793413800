import { Action, Middleware, Store } from "@reduxjs/toolkit";
import React, { createContext } from "react";
import { Subscription, throttleTime } from "rxjs";
import {
  addStats,
  reset,
  setEngineState,
  setWaveformData,
  setWholeWaveformData,
  startTest,
} from "./reducers/Testing";
import { Signal } from "./Signal";
import { ReceivedRtcpStats } from "./Stats";
import { AppDispatch } from "./store";
import { TestEngine, TestEngineState } from "./TestEngine";
import reference_waveform from "./reference_waveform.json";

export const testManagerMiddleware: Middleware<{}, {}> = (store) => {
  console.log("create test manager");
  const testmanager = new TestManager(store.dispatch);
  return (next) => (action: Action) => {
    switch (action.type) {
      case startTest.type:
        {
          testmanager.start();
        }
        break;
      default: {
        next(action);
      }
    }
  };
};

class TestManager {
  signal?: Signal;
  testEngine?: TestEngine;
  subscription?: Subscription;
  dispatch: AppDispatch;
  isDrawReference: Boolean = false;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  start = async (): Promise<boolean> => {
    if (this.testEngine && this.testEngine.isTesting) {
      return false;
    }
    this.destroyEngine();
    this.dispatch(reset());
    this.isDrawReference = false;
    this.subscription = new Subscription();
    this.signal = new Signal();
    this.testEngine = new TestEngine(
      this.signal,
      "/speech_1.3_48_converted.wav"
    );
    this.subscription?.add(
      this.testEngine.stateStream.subscribe(this.onTestEngineStateChanged)
    );
    this.subscription?.add(
      this.testEngine.serverStatsStream.subscribe(this.onStatsReceived)
    );
    this.subscription?.add(
      this.testEngine.waveformStream.subscribe(this.onWaveformDataReceived)
    );
    this.subscription?.add(
      this.testEngine.wholeWaveformStream
        .pipe(throttleTime(50))
        .subscribe(this.onWholeWaveformDataReceived)
    );
    this.subscription?.add(() => this.testEngine?.dispose());
    this.subscription?.add(() => this.signal?.dispose());
    await this.testEngine.start();
    return true;
  };

  stop = async () => {
    this.destroyEngine();
  };

  reset = async () => {
    this.destroyEngine();
    this.subscription = undefined;
    this.signal = undefined;
    this.testEngine = undefined;
  };

  private destroyEngine = async () => {
    this.subscription?.unsubscribe();
  };

  private onTestEngineStateChanged = async (state: TestEngineState) => {
    this.dispatch(setEngineState(state));
  };

  private onStatsReceived = (stats: ReceivedRtcpStats) => {
    this.dispatch(addStats(stats));
    // this.setState((s) => ({ ...s, serverstats: [...s.serverstats, stats] }));
  };

  private onWaveformDataReceived = (waveformData: number[]) => {
    this.dispatch(setWaveformData(waveformData));
  };

  private onWholeWaveformDataReceived = (waveformData: number[]) => {
    if (!this.isDrawReference) {
      const firstNonDefault = waveformData.findIndex((x) => x != 128);
      if (firstNonDefault >= 0) {
        console.log(`firstNonDefault ${firstNonDefault}`);
        this.isDrawReference = true;
        const paddedReferenceWaveform = [
          ...Array(firstNonDefault > 2 ? firstNonDefault - 3 : 0).fill(128),
          ...reference_waveform,
        ];
        this.dispatch(setWaveformData(paddedReferenceWaveform));
      }
    }
    this.dispatch(setWholeWaveformData(waveformData));
  };
}
